<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img
        :src="
          this.currentLanguage === 'fr'
            ? '/img/banners/login-banner-fr.png'
            : '/img/banners/login-banner-en.png'
        "
        style="width: 100%; border-radius: 10px; max-width: 100%"
      />
    </div>

    <section class="register-form-wrapper" v-show="action_login">
      <h3>{{ $t("logIntoYourAccount") }}</h3>
      <div class="detail">
        <p>{{ $t("enterYourDetailsContinueGaming") }}</p>
      </div>
      <div class="form-wrapper mb-3">
        <div class="text-danger" v-if="phoneInvalid">
          <img src="/img/info-circle.svg" />
          <small class="text-center">Invalid phone number</small>
        </div>
        <form id="login-form" autocomplete="off">
          <TextInput
            type="number"
            v-model="msisdn"
            inputmode="numeric"
            :placeholder="$t('phoneNumber')"
            aria-autocomplete="none"
            :name="generateRandomName('msisdn')"
            :id="generateRandomName('msisdn')"
            autocomplete="new-password"
          />
          <TextInput
            type="password"
            v-model="password"
            :placeholder="$t('enterYourPassword')"
            :name="generateRandomName('password')"
            :id="generateRandomName('password')"
            autocomplete="new-password"
          />
          <!-- <small class="text-blue mb-3 d-none">{{
            $t("enter4DigitCode")
          }}</small> -->
          <input type="hidden" name="utm_source" value="" />
          <input type="hidden" name="utm_medium" value="" />
          <input type="hidden" name="utm_campaign" value="" />
          <input type="hidden" name="referrer" value="" />
        </form>
      </div>
      <p class="forgot-password">
        <!-- <strong style="float: right"> -->
        <a
          @click="setGetResetCode"
          style="cursor: pointer; text-transform: uppercase"
        >
          {{ $t("forgotPassword") }}
        </a>
        <!-- </strong> -->
      </p>

      <ChopbetButton
        @click="handleLoginClick"
        :disabled="!(password.length > 3) || !msisdn ||
          msisdn.length < 10"
        :loading="spinner"
      >
        {{ $t("login") }}</ChopbetButton
      >

      <div class="login-wrapper">
        <p>{{ $t("dontHaveAccount") }}</p>

        <router-link to="/join" class="join-now">
          <span class="special-font" style="color: var(--slide-active)"><strong>{{ $t("joinNow") }}</strong> </span>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import TextInput from "../../../components/ui/TextInput.vue";
import CloseIcon from "../components/CloseIcon.vue";

export default {
  name: "Login",
  components: { TextInput, ChopbetButton, CloseIcon },
  comments: {},
  data: function () {
    return {
      first_name: "",
      last_name: "",
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_login: true,
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      code: new Array(4).fill(""),
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    ...mapState(["referralcode"]),
  },
  methods: {
    handleLoginClick() {
      this.login();
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },
    login: function () {
      this.reset();
      this.removeAuth();
      this.msisdn = this.msisdn.replace(/\D/g, "");
      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        return;
      }
      if (this.password.length < 4) {
        this.setError(`${this.$t("moreThan4Xters")}`);
        return;
      }
      this.loading = "loading";
      this.spinner = true;
      var vm = this;
      var path = `/login?lang=${this.$i18n.locale}`;
      const numberValue = `225${this.msisdn}`;

      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      //  const lang = localStorage.getItem("locale") || "en";
      //     config.headers["lang"] = lang;
      console.log("Document Referrer:", document.referrer);
      axios
        .post(
          path,
          JSON.stringify({
            msisdn: numberValue,
            password: this.password,
            login_tag: login_tag,
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            referrer: document.referrer,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              lang: this.$i18n.locale || "fr",
            },
          }
        )
        .then((res) => {
          vm.loading = "";
          vm.spinner = false;
          var profile = res.data;
          // var status = res.status;

          // if (parseInt(status) === 201 || parseInt(status) === 204) {
          //   localStorage.setItem("mssdn__reg_xpk", numberValue);
          //   this.$router.push("/activate-account");
          //   return;
          // }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);
          // console.log("TOKEN : " + auth);
          vm.trackLoginButtonClick(event);
          vm.EventBus.$emit("init:mqtt");
          window.location.href = "/";
        })
        .catch((err) => {
          vm.loading = "";
          vm.spinner = false;
          if (err.response) {
            const errorCode = parseInt(err.response.data.error_code);
            if (errorCode === 428) {
              localStorage.setItem("mssdn__reg_xpk", `${this.msisdn}`);
              this.$router.push("/reset-password/new");
              return;
            }
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },

    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },

    getBrowserInfo() {
      return navigator.userAgent || "unknown";
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return "JavaScript";
    },
    generateRandomName(baseName) {
      return `${baseName}-${Math.random().toString(36).substring(7)}`;
    },

    getVersionInfo() {
      return navigator.appVersion || "unknown";
    },

    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },

    setGetResetCode: function () {
      this.$router.push("/forgot-password");
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "white";
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color =
          "rgba(255,255,255,.75)";
      }

      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },
  },
  mounted: function () {
    this.msisdn = "";
    this.password = "";
    this.$store.dispatch("setCurrentPage", "login");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>

<style src="./index.css" scoped></style>
